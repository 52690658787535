import React from "react";
import Sloga from "./Sloger";
import Whate from "./Whate";
import Publicite from "./Publicite";
import Remerciement from "./Remerciement";
import Contact from "./Contact";
import HoraireTravail from "../HoraireTravail/HoraireTravail";
import Pole from "./Pole";
import Articles from "./Articles";

function AcceuilCompent() {
  return (
    <>
      <Sloga />
      <Whate />
      <Pole />
      <Publicite />
      <Articles />
      <Contact />
      <HoraireTravail />
      <Remerciement />
    </>
  );
}

export default AcceuilCompent;
