/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import SpinnerDemarage from "../../SpinnerDemarage/SpinnerDemarage";
import { useTheme } from "../UseContext/ThemeContext";
import { Accordion } from "rsuite";
import { useNavigate } from "react-router-dom";

const pdfjsWorker = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

function DocumentVisit() {
  const [article, setArticle] = useState([]);
  const { isMargin, dataArticleHomme } = useTheme();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [mobile, setMobile] = useState(window.innerWidth < 800);
  const [mobile1, setMobile1] = useState(window.innerWidth < 1178);
  const [mobile2, setMobile2] = useState(window.innerWidth < 627);
  const [mobile3, setMobile3] = useState(window.innerWidth < 443);
  const [mobile4, setMobile4] = useState(window.innerWidth < 504);
  const [isLoading, setIsLoading] = useState(true);
  const [redFile, setRedFile] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [dataServiceClicked, setDataServiceClicked] = useState(null);

  // Pour le chargement du PDF (state propre au PDF)
  const [pdfLoading, setPdfLoading] = useState(true);
  const [pdfError, setPdfError] = useState(null);

  const fetchArticle = async () => {
    try {
      const response = await axios.get(
        "https://hathaproject.burundientempsreel.com/article/all",
        {
          params: { page: currentPage, size: pageSize },
        }
      );
      setArticle(response.data.articles);
      setTotalPages(response.data.totalPages);
      if (!dataServiceClicked && response.data.articles.length > 0) {
        setDataServiceClicked(response.data.articles[0]);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching article:", error);
      setTotalPages(0);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchArticle();
  }, [currentPage, pageSize]);

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth < 800);
      setMobile1(window.innerWidth < 1178);
      setMobile2(window.innerWidth < 627);
      setMobile3(window.innerWidth < 443);
      setMobile4(window.innerWidth < 504);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const generatePageNumbers = () => {
    const maxPagesToShow = 5;
    const pages = [];
    let startPage = 3;
    let endPage = totalPages;

    if (totalPages > maxPagesToShow) {
      const offset = Math.floor(maxPagesToShow / 2);
      startPage = currentPage - offset;
      endPage = currentPage + offset;

      if (startPage < 1) {
        startPage = 3;
        endPage = maxPagesToShow;
      } else if (endPage > totalPages) {
        endPage = totalPages;
        startPage = totalPages - maxPagesToShow + 1;
      }
    }

    if (startPage > 3) {
      pages.push(
        <button
          key={1}
          onClick={() => handlePageClick(1)}
          className="py-2 px-3 mx-1 bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold rounded-lg"
        >
          1
        </button>
      );
      if (startPage > 2) {
        pages.push(<span key="dots1">...</span>);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageClick(i)}
          className={`py-2 px-3 mx-1 font-semibold rounded-lg ${
            currentPage === i
              ? "bg-gray-700 text-white"
              : "hover:bg-gray-300 bg-gray-200 text-gray-800"
          }`}
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push(<span key="dots2">...</span>);
      }
      pages.push(
        <button
          key={totalPages}
          onClick={() => handlePageClick(totalPages)}
          className="py-2 px-3 mx-1 bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold rounded-lg"
        >
          {totalPages}
        </button>
      );
    }

    return pages;
  };

  const updateDataService = (newValue) => {
    setDataServiceClicked(newValue);
    localStorage.setItem("data", JSON.stringify(newValue));
  };

  const handleLoadStart = () => {
    setPdfLoading(true);
    setPdfError(null);
  };

  const handleLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPdfLoading(false);
  };

  const handleLoadError = (error) => {
    setPdfError(error);
    setPdfLoading(false);
  };

  const navig = useNavigate();
  const [activeKey, setActiveKey] = useState(dataArticleHomme);

  return (
    <div className="w-full flex mt-1 justify-center flex-col items-center">
      {isLoading && <SpinnerDemarage />}
      <div className="p-2 w-full">
        <div className="w-full h-max">
          <div className="font-bold text-[#AF6E04] text-xl mb-4">
            Nos Réalisations
          </div>
        </div>
        <div className="w-full rounded-lg flex flex-col mt-[20px] justify-center items-center">
          <div
            className={`flex w-full ${
              isMargin || mobile1 ? "flex-col" : ""
            } p-2`}
          >
            {article.map((data, index) => (
              <Accordion
                data-position={index}
                key={index}
                activeKey={activeKey}
                className={`${
                  index > 0 ? "border-t-[0.5px]" : ""
                } border-gray-400 rounded-[0px]`}
                defaultExpanded
                onSelect={() => setActiveKey(index)}
              >
                <Accordion.Panel
                  header={
                    <div
                      className={`font-bold text-nowrap text-ellipsis overflow-hidden ${
                        mobile
                          ? mobile2
                            ? "text-[11px]"
                            : "text-[15px]"
                          : "text-[18px]"
                      }`}
                    >
                      {data?.titre}
                    </div>
                  }
                  eventKey={index}
                >
                  <div className="w-full mt-4">
                    <div
                      className={`flex border relative ${
                        mobile1 ? "h-auto" : "h-auto"
                      } flex-col w-full items-center rounded-lg`}
                    >
                      {data &&
                      data.file &&
                      data.file.toLowerCase().endsWith(".pdf") ? (
                        <>
                          <Document
                            file={`https://hathaproject.burundientempsreel.com/uploads/article/${data.file}`}
                            onLoadStart={handleLoadStart}
                            onLoadSuccess={handleLoadSuccess}
                            onLoadError={handleLoadError}
                            className="pdfViewer"
                          >
                            {numPages &&
                              Array.from(new Array(numPages), (el, index) => (
                                <Page
                                  key={`page_${index + 1}`}
                                  pageNumber={index + 1}
                                  className="pdfPage"
                                />
                              ))}
                          </Document>
                          {pdfError && (
                            <p className="text-red-600">
                              Erreur de chargement du PDF, votre connexion n'est
                              pas stable.
                            </p>
                          )}
                          {pdfLoading && (
                            <div className="absolute top-0 w-full h-full left-0 flex items-center justify-center bg-gray-50 spinners">
                              <svg
                                className="animate-spin h-8 w-8 text-[#AF6E04]"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="#AF6E04"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4.472v3.598A4.002 4.002 0 008 12H6v5.291z"
                                ></path>
                              </svg>
                            </div>
                          )}
                        </>
                      ) : (
                        data &&
                        data.file && (
                          <img
                            onLoad={() => setIsLoading(false)}
                            className="object-cover w-full h-full"
                            src={`https://hathaproject.burundientempsreel.com/uploads/article/${data.file}`}
                            alt=""
                          />
                        )
                      )}
                      {isLoading && (
                        <div className="absolute top-0 w-full h-full left-0 flex items-center justify-center bg-gray-50 spinners">
                          <svg
                            className="animate-spin h-8 w-8 text-[#AF6E04]"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="#AF6E04"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4.472v3.598A4.002 4.002 0 008 12H6v5.291z"
                            ></path>
                          </svg>
                        </div>
                      )}
                    </div>
                  </div>
                </Accordion.Panel>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DocumentVisit;
